<template>
  <footer class="py-12">
    <div class="border-t"></div>
    <div class="max-w-[1320px] px-4 mx-auto">

      <div class="flex border-b py-12">
        <div class="w-full flex flex-col md:flex-row gap-16">
          <div>
            <img src="../assets/logo-green.svg" alt="logo"/>
          </div>

          <div class="flex gap-8 text-xl md:text-2xl">
            <div class="flex flex-col gap-4 font-medium">
              <a @click="goTo('company')" class="cursor-pointer">About ARC</a>
              <a @click="goTo('technology')" class="cursor-pointer">Technology</a>
              <a @click="goTo('solutions')" class="cursor-pointer">Solution</a>
              <a>FAQs</a>
            </div>

            <div class="flex flex-col gap-4 text-brand-gray-secondary6">
              <a @click="goTo('products-airis')" class="cursor-pointer">Platform <span class="text-brand-green">Airis</span></a>
              <a @click="goTo('products-airis-lite')" class="cursor-pointer">Platform <span class="text-brand-green">Airis Lite</span></a>
            </div>
          </div>
        </div>

        <div class="hidden md:block">
          <img src="../assets/parcel.svg" alt="parcel"/>
        </div>
      </div>

      <div class="w-full flex flex-col md:flex-row justify-between items-start text-brand-gray-secondary6 py-6">
        <div class="order-last md:order-first flex justify-between items-center text-sm w-full md:w-fit">
          <p class="w-36">Copyright 2024, ARC.
            All rights reserved.
          </p>

          <div class="md:hidden">
            <img src="../assets/designed.svg" alt="designed"/>
          </div>
        </div>

        <div class="grid grid-cols-3 md:grid-cols-5 gap-6 font-medium text-sm mb-10">
          <a>LinkedIn</a>
          <a>Facebook</a>
          <a>Instagram</a>
          <a>Twitter</a>
          <a>Youtube</a>
        </div>

        <div class="hidden md:block">
          <img src="../assets/designed.svg" alt="designed"/>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSection",
  methods: {
    goTo(name, hash = null) {
      this.showMenu = false
      if (hash) {
        this.$router.push({name: name, hash: '#' + hash})
      }else {
        this.$router.push({name: name})
      }
    }
  }
};
</script>
<template>
  <!-- Hero -->
  <div class="h-screen w-full flex justify-center items-center mb-20 relative bg-black bg-opacity-50">
    <div class="text-center max-w-[783px] text-white absolute bottom-[120px] px-4">
      <p class="font-medium text-xs uppercase mb-8 w-fit mx-auto px-4 py-2 bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm tracking-[.20em] rounded-2xl">
        innovative services
      </p>

      <p class="text-3xl font-primary leading-10">
        By leveraging cutting-edge drone technology, we provide a cost-effective alternative to conventional delivery <br/>services.
      </p>
    </div>

    <img src="../assets/4bg.jpg" class="w-full h-screen object-cover object-center absolute -z-10"/>
  </div>

  <!-- Discover -->
  <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] w-full mx-auto rounded-3xl py-12 md:py-24 relative flex flex-col md:flex-row justify-center items-center gap-4 bg-[#F4FAF4]">
    <div class="md:w-1/2 order-last md:order-first h-72 md:h-full w-full flex justify-center md:flex-col">
      <img src="../assets/phone.svg" class="absolute bottom-0 z-10 md:mx-auto md:left-32 w-3/4 md:w-auto" alt="phone"/>
      <img src="../assets/line.png" class="absolute top-2/3 left-0 md:left-auto md:top-1/3"/>
    </div>

    <div class="md:w-1/2 z-20">
      <div class="max-w-xl p-3 md:p-4">
        <p class="font-medium text-3xl md:text-5xl text-center md:text-left mb-5">
          <span class="text-brand-green">Discover the future of logistics</span> with our innovative drone delivery service
        </p>

        <p class="text-center md:text-left mb-10">
          We are dedicated to revolutionizing the way you receive goods, offering unmatched speed, reliability, and efficiency.
        </p>

        <div class="flex flex-col gap-6 md:gap-8 ml-8 md:ml-0 ">
          <div class="flex gap-3 items-center">
            <img src="../assets/icon1.svg" alt="icon">
            <p>Speed up your logistics</p>
          </div>

          <div class="flex gap-3 items-center">
            <img src="../assets/icon1.svg" alt="icon">
            <p>Minimize delays</p>
          </div>

          <div class="flex gap-3 items-center">
            <img src="../assets/icon1.svg" alt="icon">
            <p>Reduce operational costs</p>
          </div>

          <div class="flex gap-3 items-center w-56">
            <img src="../assets/icon1.svg" alt="icon">
            <p>Deliver unprecedented
              future service</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Feature1 -->
  <div id="delivery" class="xl:max-w-[1320px] 2xl:max-w-[1380px] w-full mx-auto px-4 flex flex-col md:flex-row items-start py-12 md:py-24 gap-10 md:gap-16">
    <div class="md:w-1/2">
      <p class="font-medium text-3xl md:text-5xl text-brand-green mb-8">Delivery</p>
      <p class="text-xl md:text-2xl mb-8 max-w-[600px]">
        We develop courier drones for delivering food, medication, and parcels.  Our inventory includes drones for medical delivery, food delivery, and  heavy-lifting drones.
      </p>
      <p class="md:text-lg mb-12 max-w-[536px]">
        Our delivery service is ideal for various sectors <span class="text-brand-green">including e-commerce, healthcare, food and beverage, and logistics.</span> Whether you need to send a critical medical supply, deliver a fresh meal, or ensure swift shipping of online orders, our drones are designed to meet diverse delivery needs efficiently.
      </p>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div>
          <div class="flex mb-3">
            <img src="../assets/tick.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Speed and Efficiency
            </p>
          </div>

          <p>Reduce delivery times significantly with our rapid drone deployments.</p>
        </div>

        <div>
          <div class="flex mb-3">
            <img src="../assets/tick.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Eco-Friendly
            </p>
          </div>

          <p>Lower carbon footprint compared to traditional delivery methods.</p>
        </div>

        <div>
          <div class="flex mb-3">
            <img src="../assets/tick.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Real-Time Tracking
            </p>
          </div>

          <p>Monitor your package in real-time with our advanced tracking systems.</p>
        </div>
      </div>
    </div>

    <div class="md:w-1/2 flex justify-end">
      <img src="../assets/tf1.svg" class="rounded-2xl"/>
    </div>
  </div>

  <!-- Feature2 -->
  <div id="fleet" class="xl:max-w-[1320px] 2xl:max-w-[1380px] w-full mx-auto px-4 flex flex-col md:flex-row items-start py-12 md:py-24 gap-10">
    <div class="order-last md:order-first md:w-1/2">
      <img src="../assets/tf2.svg" class="rounded-2xl"/>
    </div>

    <div class="md:w-1/2">
      <p class="font-medium text-3xl md:text-5xl text-brand-blue mb-4">Fleet management</p>
      <p class="text-xl md:text-2xl mb-8">
        GCS Software is a pivotal tool for managing autonomous delivery drone operations
      </p>
      <p class="md:text-lg mb-10">
        Our GCS offers real-time tracking, telemetry data, and mission controls through an intuitive interface. This system optimizes efficiency and safety in the rapidly growing drone delivery market. This service is perfect for businesses and organizations that operate  multiple drones, such as logistics companies, large-scale delivery  services, and emergency response teams. Our management tools help  streamline operations, reduce downtime, and improve overall fleet  performance.
      </p>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div>
          <div class="flex mb-3">
            <img src="../assets/tick2.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Centralized Control
            </p>
          </div>

          <p>Manage all your drones from a single, user-friendly interface.</p>
        </div>

        <div>
          <div class="flex mb-3">
            <img src="../assets/tick2.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Automated Scheduling
            </p>
          </div>

          <p>Efficiently plan and deploy your drones based on real-time data and analytics.</p>
        </div>

        <div>
          <div class="flex mb-3">
            <img src="../assets/tick2.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Maintenance Tracking
            </p>
          </div>

          <p>Keep track of maintenance schedules and ensure your drones are always flight-ready.</p>
        </div>

        <div>
          <div class="flex mb-3">
            <img src="../assets/tick2.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Compliance Monitoring
            </p>
          </div>

          <p>Stay updated with regulatory requirements and ensure all flights comply with local laws.</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Feature3 -->
  <div id="mini" class="xl:max-w-[1320px] 2xl:max-w-[1380px] w-full mx-auto px-4 flex flex-col md:flex-row items-start py-12 md:py-24 gap-10 md:gap-12">
    <div class="md:w-1/2">
      <p class="font-medium text-3xl md:text-5xl text-brand-orange mb-6">Mini-airport Installation</p>
      <p class="text-xl md:text-2xl mb-7 max-w-[600px]">
        Our mini-airport installation service provides the infrastructure necessary for efficient drone operations.
      </p>
      <p class="md:text-lg mb-10 max-w-[648px]">
        We design and install compact, yet fully functional drone ports that serve as hubs for launching, landing, and maintaining drones. These mini-airports are equipped with charging stations, maintenance facilities, and automated management systems. Ideal for urban and suburban areas, our mini-airports are perfect for businesses and municipalities looking to establish a network of drone delivery points. These installations support quick turnarounds and help expand the reach of drone services in dense population centers or remote areas.
      </p>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <div class="flex mb-3">
            <img src="../assets/tick3.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Integrated Facilities
            </p>
          </div>

          <p>Equipped with charging and maintenance stations for seamless operations.</p>
        </div>

        <div>
          <div class="flex mb-3">
            <img src="../assets/tick3.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Automated Systems
            </p>
          </div>

          <p>Features automated takeoff, landing, and docking systems to minimize human intervention.</p>
        </div>

        <div>
          <div class="flex mb-3">
            <img src="../assets/tick3.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Scalability
            </p>
          </div>

          <p>Modular design allows for easy expansion as your drone network grows.</p>
        </div>
      </div>
    </div>

    <div class="md:w-1/2">
      <img src="../assets/tf3.svg" class="rounded-2xl"/>
    </div>
  </div>

  <!-- Feature4 -->
  <div id="education" class="xl:max-w-[1320px] 2xl:max-w-[1380px] w-full mx-auto px-4 flex flex-col md:flex-row items-start py-12 md:py-24 gap-10 md:gap-12">
    <div class="order-last md:order-first md:w-1/2">
      <img src="../assets/tf4.svg" class="rounded-2xl"/>
    </div>

    <div class="md:w-1/2">
      <p class="font-medium text-3xl md:text-5xl mb-8">Education/Training</p>
      <p class="text-xl md:text-2xl mb-7">
        Our education and training services offer comprehensive programs to equip individuals and teams with the knowledge and skills needed to operate and manage drones effectively.
        Our curriculum covers basic to advanced drone operation, safety protocols, regulatory compliance, and practical flight training.
      </p>
      <p class="md:text-lg mb-10">
        These training programs are designed for aspiring drone pilots, corporate teams, law enforcement agencies, and emergency responders. Whether you are a beginner or an experienced operator looking to refine your skills, our courses cater to a wide range of proficiency levels.
      </p>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <div class="flex mb-3">
            <img src="../assets/tick4.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Expert Instructors
            </p>
          </div>

          <p>Learn from experienced professionals with extensive industry knowledge.</p>
        </div>

        <div>
          <div class="flex mb-3">
            <img src="../assets/tick4.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Hands-On Training
            </p>
          </div>

          <p>Gain practical experience with flight simulations and real-world exercises.</p>
        </div>

        <div>
          <div class="flex mb-3">
            <img src="../assets/tick4.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Certification
            </p>
          </div>

          <p>Receive certification upon completion, recognized by industry standards.</p>
        </div>

        <div>
          <div class="flex mb-3">
            <img src="../assets/tick4.svg" class="mr-3"/>
            <p class="font-medium text-xl">
              Customized Programs
            </p>
          </div>

          <p>Tailored training modules to meet specific industry needs and requirements.</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Industry Experience -->
  <div class="w-full" :class="slide.color">
    <div class="max-w-7xl mx-auto px-4 py-12 md:py-24" id="solutions">
      <p class="text-brand-orange font-medium md:text-xl uppercase tracking-widest mb-4">Industry Experience</p>
      <p class="text-3xl md:text-5xl font-medium max-w-xl mb-12 md:mb-24">
        Business solutions tailored
        for your industry
      </p>

      <div class="flex flex-col md:flex-row">
        <div class="md:w-1/4 w-full flex md:flex-col gap-4 overflow-x-scroll font-medium text-xl md:text-3xl mb-5">
          <a
              v-for="link in links"
              :key="link.id"
              @click="selectSlide(link.id)"
              class="cursor-pointer"
              :class="link.id === slide.id ? 'text-brand-black' : 'text-[#BCC0B3]'"
          >{{link.name}}</a>
        </div>

        <div class="md:w-3/4">
          <img :src="slide.image" alt="img" class="mb-8 rounded">
          <p class="md:text-2xl mb-12">
            {{slide.description}}
          </p>

          <div class="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-4">
            <div
              v-for="(f, index) in slide.features"
              :class="index"
            >
              <div class="flex mb-3">
                <img src="../assets/tick.svg" class="mr-3"/>
                <p class="font-medium text-xl">
                  {{f.title}}
                </p>
              </div>

              <p>
                {{f.description}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- FAQ -->
  <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] w-full mx-auto px-4 py-12">
    <p class="text-3xl md:text-4xl mb-10">FAQ</p>

    <div
        v-for="(item, index) in questions"
        :key="item.q"
        class="w-full cursor-pointer"
        @click="openItem(index)"
    >
      <div class="py-5 border-b">
        <div class="flex items-center justify-between">
          <p class="md:text-2xl font-medium">{{item.q}}</p>

          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 md:w-8 md:h-8">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </div>

        <div v-if="item.isVisible" class="text-brand-black mt-3">
          {{item.a}}
        </div>
      </div>
    </div>
  </div>

  <!-- Last -->
  <last/>
</template>

<script>
import Last from "@/components/Last.vue";

export default {
  name: 'SolutionsView',
  components: {Last},
  data: () => ({
    questions: [
      {
        q: 'What is Drone Delivery?',
        a: 'Drone delivery is a service that uses unmanned aerial vehicles (UAVs) to  transport packages directly to your doorstep. It\'s a fast, efficient,  and innovative way to receive your orders.',
        isVisible: false
      },
      {
        q: 'How does the Drone Delivery service work?',
        a: 'Drone delivery is a service that uses unmanned aerial vehicles (UAVs) to  transport packages directly to your doorstep. It\'s a fast, efficient,  and innovative way to receive your orders.',
        isVisible: false
      },
      {
        q: 'How does the Drone Delivery service work?',
        a: 'Drone delivery is a service that uses unmanned aerial vehicles (UAVs) to  transport packages directly to your doorstep. It\'s a fast, efficient,  and innovative way to receive your orders.',
        isVisible: false
      },
      {
        q: 'How does the Drone Delivery service work?',
        a: 'Drone delivery is a service that uses unmanned aerial vehicles (UAVs) to  transport packages directly to your doorstep. It\'s a fast, efficient,  and innovative way to receive your orders.',
        isVisible: false
      },
      {
        q: 'What areas are covered by the Drone Delivery service?',
        a: 'Drone delivery is a service that uses unmanned aerial vehicles (UAVs) to  transport packages directly to your doorstep. It\'s a fast, efficient,  and innovative way to receive your orders.',
        isVisible: false
      },
      {
        q: 'What types of items can be delivered by drone?',
        a: 'Drone delivery is a service that uses unmanned aerial vehicles (UAVs) to  transport packages directly to your doorstep. It\'s a fast, efficient,  and innovative way to receive your orders.',
        isVisible: false
      },
      {
        q: 'How long does delivery take?',
        a: 'Drone delivery is a service that uses unmanned aerial vehicles (UAVs) to  transport packages directly to your doorstep. It\'s a fast, efficient,  and innovative way to receive your orders.',
        isVisible: false
      },
      {
        q: 'Is Drone Delivery safe?',
        a: 'Drone delivery is a service that uses unmanned aerial vehicles (UAVs) to  transport packages directly to your doorstep. It\'s a fast, efficient,  and innovative way to receive your orders.',
        isVisible: false
      },
      {
        q: 'How much does Drone Delivery cost?',
        a: 'Drone delivery is a service that uses unmanned aerial vehicles (UAVs) to  transport packages directly to your doorstep. It\'s a fast, efficient,  and innovative way to receive your orders.',
        isVisible: false
      },
      {
        q: 'What if I need to return an item?',
        a: 'Drone delivery is a service that uses unmanned aerial vehicles (UAVs) to  transport packages directly to your doorstep. It\'s a fast, efficient,  and innovative way to receive your orders.',
        isVisible: false
      }
    ],
    slides: [
      {
        id: 0,
        color: 'bg-[#EBF7FC]',
        image: './img/ind1.png',
        description: 'Our drone delivery service offers a cutting-edge logistics solution for enterprises, transforming the way goods are transported within and between facilities. Whether you’re in manufacturing, retail, or healthcare, our drones can help streamline operations, reduce costs, and improve efficiency.',
        features: [
          {
            title: 'Rapid Internal Logistics',
            description: 'Quickly move parts, products, or documents between different locations within large facilities.'
          },
          {
            title: 'Just-In-Time Delivery',
            description: 'Ensure timely delivery of critical components and supplies to keep production lines running smoothly.'
          },
          {
            title: 'Inventory Management',
            description: 'Enhance inventory control with frequent, automated deliveries from warehouses to distribution centers.'
          }
        ]
      },
      {
        id: 1,
        color: 'bg-[#F5EBFC]',
        image: './img/ind2.png',
        description: 'Our drone delivery service offers a cutting-edge logistics solution for enterprises, transforming the way goods are transported within and between facilities. Whether you’re in manufacturing, retail, or healthcare, our drones can help streamline operations, reduce costs, and improve efficiency.',
        features: [
          {
            title: 'Rapid Internal Logistics',
            description: 'Quickly move parts, products, or documents between different locations within large facilities.'
          },
          {
            title: 'Just-In-Time Delivery',
            description: 'Ensure timely delivery of critical components and supplies to keep production lines running smoothly.'
          },
          {
            title: 'Inventory Management',
            description: 'Enhance inventory control with frequent, automated deliveries from warehouses to distribution centers.'
          }
        ]
      },
      {
        id: 2,
        color: 'bg-[#F1FCEB]',
        image: './img/ind3.png',
        description: 'Our drone delivery service offers a cutting-edge logistics solution for enterprises, transforming the way goods are transported within and between facilities. Whether you’re in manufacturing, retail, or healthcare, our drones can help streamline operations, reduce costs, and improve efficiency.',
        features: [
          {
            title: 'Rapid Internal Logistics',
            description: 'Quickly move parts, products, or documents between different locations within large facilities.'
          },
          {
            title: 'Just-In-Time Delivery',
            description: 'Ensure timely delivery of critical components and supplies to keep production lines running smoothly.'
          },
          {
            title: 'Inventory Management',
            description: 'Enhance inventory control with frequent, automated deliveries from warehouses to distribution centers.'
          }
        ]
      },
      {
        id: 3,
        color: 'bg-[#FCF8EB]',
        image: './img/ind4.png',
        description: 'Our drone delivery service offers a cutting-edge logistics solution for enterprises, transforming the way goods are transported within and between facilities. Whether you’re in manufacturing, retail, or healthcare, our drones can help streamline operations, reduce costs, and improve efficiency.',
        features: [
          {
            title: 'Rapid Internal Logistics',
            description: 'Quickly move parts, products, or documents between different locations within large facilities.'
          },
          {
            title: 'Just-In-Time Delivery',
            description: 'Ensure timely delivery of critical components and supplies to keep production lines running smoothly.'
          },
          {
            title: 'Inventory Management',
            description: 'Enhance inventory control with frequent, automated deliveries from warehouses to distribution centers.'
          }
        ]
      }
    ],
    slide: {
      id: 0,
      color: 'bg-[#EBF7FC]',
      image: './img/ind1.png',
      description: 'Our drone delivery service offers a cutting-edge logistics solution for enterprises, transforming the way goods are transported within and between facilities. Whether you’re in manufacturing, retail, or healthcare, our drones can help streamline operations, reduce costs, and improve efficiency.',
      features: [
        {
          title: 'Rapid Internal Logistics',
          description: 'Quickly move parts, products, or documents between different locations within large facilities.'
        },
        {
          title: 'Just-In-Time Delivery',
          description: 'Ensure timely delivery of critical components and supplies to keep production lines running smoothly.'
        },
        {
          title: 'Inventory Management',
          description: 'Enhance inventory control with frequent, automated deliveries from warehouses to distribution centers.'
        }
      ]
    },
    links: [
      {
        id: 0,
        name: 'Enterprises'
      },
      {
        id: 1,
        name: 'Businesses'
      },
      {
        id: 2,
        name: 'Governments'
      },
      {
        id: 3,
        name: 'Home Delivery'
      }
    ]
  }),
  methods: {
    openItem(index) {
      this.questions.map((x, idx) => {
        if (index === idx) {
          x.isVisible =! x.isVisible
        }else {
          x.isVisible = false
        }
      })
    },
    selectSlide(index) {
      this.slide = this.slides[index]
    }
  }
}
</script>

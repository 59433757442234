<template>
  <!-- Hero -->
  <div class="h-screen lg:h-[1080px] bg-black bg-opacity-40 flex justify-center items-center px-8 relative">
    <div class="text-center max-w-xl text-white absolute bottom-[160px]">
      <p class="font-medium text-xs uppercase mb-6 w-fit mx-auto px-4 py-2 bg-white bg-opacity-15 backdrop-filter backdrop-blur-sm tracking-[.20em] rounded-2xl">
        Our Technology
      </p>

      <p class="text-3xl">Revolutionizing Delivery with Cutting-Edge
        Drone Technology</p>
    </div>

    <img src="../assets/top.jpg" class="w-full h-screen lg:h-[1080px] object-cover object-center absolute -z-10"/>
  </div>

  <!-- B1 -->
  <div class="w-full bg-gradient-to-br from-[#DDF1FF] to-[#FFECEC]">
    <div class="max-w-[1360px] w-full mx-auto px-4 py-24">
      <div class="max-w-[710px] mx-auto text-center mb-16 px-4">
        <p class="font-medium text-brand-green text-2xl md:text-4xl mb-8 tracking-wide">Discover the advanced systems and capabilities that make our drone  delivery solutions exceptional.</p>
        <p class="max-w-[627px] text-lg text-center mx-auto">Our state-of-the-art autonomous drone technology is at the heart of our  innovative delivery services. Engineered for precision, reliability, and  efficiency, our drones set new standards in the logistics industry. </p>
      </div>

      <div class="flex flex-col md:flex-row items-center gap-6">
        <div class="md:w-1/3 w-full h-[400px] bg-white rounded-2xl relative p-8 flex justify-center items-center">
          <img src="../assets/drone51.png" class="md:w-48 md:h-48 h-36 w-36 object-cover" alt="img"/>
          <p class="text-xl absolute bottom-8 left-8">Autonomous
            <span class="bg-gradient-to-r from-[#68D366] via-[#6ECF22] to-[#58ACFA] bg-clip-text text-transparent">Navigation</span></p>
        </div>

        <div class="md:w-1/3 w-full flex flex-col gap-6">
           <div class="bg-white h-[188px] rounded-2xl w-full relative">
            <div class="md:w-2/3 w-full h-full p-8 flex flex-col justify-between">
              <div>
                <img src="../assets/arr1.svg" alt="arr" class="mb-3">
                <img src="../assets/arr2.svg" alt="arr">
              </div>

              <p class="text-xl">
                <span class="bg-gradient-to-r from-[#68D366] via-[#6ECF22] to-[#58ACFA] bg-clip-text text-transparent">Long-Range Delivery</span>
                <br>Capabilities
              </p>
            </div>

             <img src="../assets/52.svg" class="absolute top-0 right-0"/>
           </div>

          <div class="bg-white p-6 h-[188px] rounded-2xl relative flex items-end">
            <img src="../assets/route.svg" alt="img" class="absolute top-0 right-12 h-full"/>
            <img src="../assets/geo.svg" alt="img" class="absolute top-4 right-16"/>
            <p class="text-xl w-56">
              Real-Time
              <span class="bg-gradient-to-r from-[#68D366] via-[#6ECF22] to-[#58ACFA] bg-clip-text text-transparent">Tracking and Monitoring</span>
            </p>
          </div>
        </div>

        <div class="md:w-1/3 w-full h-[400px] bg-[#101110] rounded-2xl relative p-8 flex justify-center items-center">
          <img src="../assets/52.png" class="h-56 object-cover object-center absolute top-16 left-3 md:left-auto md:top-auto z-10" alt="img"/>
          <img src="../assets/52.png" class="h-56 object-cover object-center absolute top-10 md:left-14 blur-sm" alt="img"/>

          <p class="text-xl absolute bottom-8 left-8 text-white">Intelligent
            <span class="bg-gradient-to-r from-[#68D366] via-[#6ECF22] to-[#58ACFA] bg-clip-text text-transparent">Fleet Management</span></p>
        </div>
      </div>
    </div>
  </div>

  <!-- B3 -->
  <div class="w-full bg-[#FFFAF5]">
    <div class="max-w-6xl mx-auto px-4 py-12 md:py-24">
      <div class="text-center max-w-[627px] mx-auto mb-20">
        <p class="text-brand-green font-medium uppercase tracking-widest mb-5">Technology</p>
        <p class="font-medium text-2xl md:text-3xl mb-4">Autonomous Navigation</p>
        <p class="md:text-lg mb-10">
          Our drones are equipped with advanced autonomous navigation systems that  ensure accurate and efficient routing. Utilizing GPS, computer vision,  and AI algorithms, our drones can navigate complex environments with  ease, avoiding obstacles and optimizing flight paths.
        </p>
      </div>

      <div class="max-w-5xl mx-auto mb-32">
        <img src="../assets/53.svg" class="w-full">
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
        <div>
          <img src="../assets/511.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-brand-orange">GPS and Computer Vision Integration</p>
          <p>Manage all your drones from a single,
            user-friendly interface.</p>
        </div>

        <div>
          <img src="../assets/512.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-brand-orange">AI Algorithms</p>
          <p>
            Uses machine learning to improve route efficiency and adapt to changing conditions.
          </p>
        </div>

        <div>
          <img src="../assets/513.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-brand-orange">Obstacle Detection and
            Avoidance</p>
          <p>
            Ensures safe flights even in congested or unpredictable environments.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- B4 -->
  <div class="w-full bg-[#E9F4E9]">
    <div class="max-w-6xl mx-auto px-4 py-12 md:py-24">
      <div class="text-center max-w-[627px] mx-auto mb-20">
        <p class="text-brand-green font-medium uppercase tracking-widest mb-5">Technology</p>
        <p class="font-medium text-2xl md:text-3xl mb-4">Long-Range Delivery Capabilities</p>
        <p class="md:text-lg mb-10">
          Designed for long-range operations, our drones can cover extensive  distances without compromising on speed or payload capacity. This  capability allows us to extend our delivery services to remote and  hard-to-reach areas, ensuring accessibility and convenience for all  customers.
        </p>
      </div>

      <div class="max-w-3xl mx-auto mb-12">
        <img src="../assets/drone54.svg" class="w-full">
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
        <div>
          <img src="../assets/icon21.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-brand-green">Extended Flight Range</p>
          <p>
            Capable of traveling significant distances
            on a single charge
          </p>
        </div>

        <div>
          <img src="../assets/icon22.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-brand-green">High Payload Capacity</p>
          <p>
            Designed to carry a variety of package
            sizes and weights
          </p>
        </div>

        <div>
          <img src="../assets/icon23.svg" class="mb-3"/>
          <p class="text-xl font-medium mb-2 text-brand-green">Fast and Efficient</p>
          <p>
            Maintains high speed and efficiency over long distances, ensuring timely deliveries
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- B5 -->
  <div class="w-full bg-[#F8F8F8]">
    <div class="max-w-6xl mx-auto px-4 py-12 md:py-24">
      <div class="text-center max-w-[627px] mx-auto">
        <p class="text-brand-green font-medium uppercase tracking-widest mb-5">Technology</p>
        <p class="font-medium text-2xl md:text-3xl mb-4">Real-Time Tracking and Monitoring</p>
        <p class="md:text-lg mb-4">
          Stay informed about your delivery status with our real-time tracking and  monitoring systems. Our technology provides live updates on the  location and status of your package, ensuring transparency and peace of  mind.
        </p>
      </div>

      <div class="max-w-5xl mx-auto w-full mb-8">
        <img src="../assets/54.svg" class="w-full">
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
        <div>
          <img src="../assets/icon31.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-brand-orange">Live Tracking</p>
          <p>
            Monitor your package in real-time
            from departure to arrival.
          </p>
        </div>

        <div>
          <img src="../assets/icon32.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-brand-orange">Status Notifications</p>
          <p>
            Receive updates and notifications at key stages of the delivery process
          </p>
        </div>

        <div>
          <img src="../assets/icon33.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-brand-orange">Data Security</p>
          <p>
            Ensures the protection of sensitive information throughout the tracking process
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- B6 -->
  <div class="w-full bg-[#E9EAF4]">
    <div class="max-w-6xl mx-auto px-4 py-24">
      <div class="text-center max-w-[627px] mx-auto mb-10">
        <p class="text-brand-green font-medium uppercase tracking-widest mb-5">Technology</p>
        <p class="font-medium text-2xl md:text-3xl mb-4">Intelligent Fleet Management</p>
        <p class="md:text-lg">
          Our fleet management system offers comprehensive oversight and control  of our drone operations. This technology enables efficient scheduling,  maintenance, and deployment of drones, maximizing the effectiveness of  our delivery network.
        </p>
      </div>

      <div class="max-w-4xl mx-auto w-full mb-20">
        <img src="../assets/55.svg" class="w-full">
      </div>

      <div class="grid grid-cols-1 md:grid-cols-3 gap-12">
        <div>
          <img src="../assets/icon41.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-[#2A2AC3]">Centralized Management
            Platform
          </p>
          <p>Manage all drone activities from
            a single interface</p>
        </div>

        <div>
          <img src="../assets/icon42.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-[#2A2AC3]">Automated Scheduling</p>
          <p>
            Streamlines flight planning and execution based on real-time data
          </p>
        </div>

        <div>
          <img src="../assets/icon43.svg" class="mb-3"/>
          <p class="md:text-xl font-medium mb-2 text-[#2A2AC3]">Predictive Maintenance</p>
          <p>
            Monitors drone health and schedules maintenance to prevent downtime
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Last -->
  <last/>
</template>

<script>
import Last from "@/components/Last.vue";

export default {
  name: 'TechnologyView',
  components: {Last}
}
</script>

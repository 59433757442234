<template>
  <!-- Hero -->
  <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] mx-auto px-4 py-12 md:py-24">
    <img src="../assets/drone2.svg" alt="drone" class="mx-auto my-16"/>
    <p class="text-3xl md:text-7xl text-[#B4B4B4] mb-10 text-center">Platform <span class="text-brand-green">Airis</span></p>
    <p class="text-brand-gray-secondary5 md:max-w-[728px] mx-auto md:text-xl mb-10 text-center">
      Introducing our <span class="text-brand-black">Platform Airis</span> model: boasting a substantial payload capacity and a short-to-medium flight range,
      it's ideal for <span class="text-brand-green">businesses, clinics, and home deliveries.</span>
      Streamlined for simplicity without compromising on performance, it's the perfect solution for efficient and versatile delivery operations.
    </p>

    <div class="border rounded-3xl p-3 flex items-start justify-center gap-2 md:gap-4 text-xs md:text-base font-medium w-fit mx-auto">
      <div class="bg-brand-green-tonal text-brand-green py-2 px-4 rounded-lg">
        <p>Home delivery</p>
      </div>

      <div class="bg-brand-blue-tonal text-brand-blue py-2 px-4 rounded-lg">
        <p>Healthcare</p>
      </div>

      <div class="bg-brand-orange-tonal text-brand-orange py-2 px-4 rounded-lg">
        <p>Restaurants</p>
      </div>
    </div>
  </div>

  <!-- Innovative solution -->
  <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] mx-auto px-4 py-12 flex flex-col md:flex-row items-center gap-10">
    <div class="max-w-[624px]">
      <p class="font-medium text-2xl md:text-3xl mb-8">
        An innovative solution for modern logistics, combining reliability, efficiency, and environmental responsibility.
      </p>

      <p class="md:text-lg">
        Advanced navigation systems and obstacle avoidance technologies ensure  safe and efficient execution of delivery missions even in challenging  urban environments.
      </p>
    </div>

    <div class="md:w-1/2 flex justify-end">
      <img src="../assets/2drone2.png" class="rounded-2xl object-cover"/>
    </div>
  </div>

  <!-- Stat -->
  <div class="max-w-6xl grid grid-cols-2 md:grid-cols-4 gap-8 text-center mx-auto px-4 py-12">
    <div>
      <p class="uppercase text-xs md:text-base text-brand-gray-secondary6 mb-2">Flight distance</p>
      <div class="flex items-center justify-center font-medium">
        <span class="text-4xl md:text-6xl mr-2">25</span>
        <span class="uppercase">km</span>
      </div>
    </div>

    <div>
      <p class="uppercase text-xs md:text-base text-brand-gray-secondary6 mb-2">Max payload weight</p>
      <div class="flex items-center justify-center font-medium">
        <span class="text-4xl md:text-6xl mr-2">9.5</span>
        <span class="uppercase">kg</span>
      </div>
    </div>

    <div>
      <p class="uppercase text-xs md:text-base text-brand-gray-secondary6 mb-2">Max speed</p>
      <div class="flex items-center justify-center font-medium">
        <span class="text-4xl md:text-6xl mr-2">22</span>
        <span class="uppercase">m/s</span>
      </div>
    </div>

    <div>
      <p class="uppercase text-xs md:text-base text-brand-gray-secondary6 mb-2">Flight Time</p>
      <div class="flex items-center justify-center font-medium">
        <span class="text-4xl md:text-6xl mr-2">25</span>
        <span class="uppercase">km</span>
      </div>
    </div>
  </div>

  <!-- Video -->
  <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] w-full mx-auto px-4 py-12 my-12 rounded-3xl bg-bg7 bg-cover bg-center h-[560px] flex justify-center items-center">
    <img src="../assets/play-button.svg" alt="play button">
  </div>

  <!-- Tech specs -->
  <div class="xl:max-w-[1320px] 2xl:max-w-[1380px] mx-auto px-4 py-24">
    <p class="text-2xl md:text-3xl text-center mb-10">Technical specifications</p>

    <div
      v-for="(item, index) in specs"
      :key="item.name"
      class="w-full cursor-pointer"
      @click="openItem(index)"
    >
      <div class="flex items-center justify-between py-5 border-b">
        <p class="text-xl md:text-2xl">{{item.name}}</p>

        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 md:w-8 md:h-8">
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </div>

      <div class="py-4" v-show="item.isVisible">
        <div
          v-for="data in item.data"
          :key="data.name"
          class="flex"
        >
          <p class="mb-1 w-56">{{data.name}}</p>
          <p class="mb-1">{{data.value}}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- Last -->
  <last/>
</template>

<script>
import Last from "@/components/Last.vue";

export default {
  name: 'ProductsView',
  components: {Last},
  data: () => ({
    specs: [
      {
        name: 'Aircraft',
        data: [
          {
            name: 'Weight',
            value: '62 kg'
          },
          {
            name: 'Max Takeoff Weight',
            value: '85 kg'
          },
          {
            name: 'Max Diagonal Wheelbase',
            value: '2000 mm'
          },
          {
            name: 'Dimensions (L x W x H)',
            value: '2600 x 3080 x 860'
          },
          {
            name: 'Max Flight Distance',
            value: '25 km'
          },
          {
            name: 'Max Flight Time',
            value: '20 mins'
          },
        ],
        isVisible: false
      },
      {
        name: 'Propulsion System',
        data: [
          {
            name: 'Weight',
            value: '62 kg'
          },
        ],
        isVisible: false
      },
      {
        name: 'Delivery Payload',
        data: [
          {
            name: 'Weight',
            value: '62 kg'
          },
        ],
        isVisible: false
      },
      {
        name: 'Features',
        data: [
          {
            name: 'Weight',
            value: '62 kg'
          },
        ],
        isVisible: false
      }
    ]
  }),
  methods: {
    openItem(index) {
      this.specs.map((x, idx) => {
        if (index === idx) {
          x.isVisible =! x.isVisible
        }else {
          x.isVisible = false
        }
      })
    }
  }
}
</script>
